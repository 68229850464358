$(window).on('load', function () {
  let player;

  $('.stream-slider__slide-preview').on('click', function (ev) {
    const channel = $(this).data('channel');

    if (player) {
      player.setChannel(channel);
    } else {
      player = new Twitch.Player("modal-stream", {
        width: '100%',
        height: '100%',
        channel,
      });
    }
  });

  $(".stream-slider__slide-preview").fancybox({
    afterClose: function() {
      if (player) {
        player.pause();
      }
    }
  });

  $(document).click(function (event) {
    if ($(event.target).closest('.profile-nav__submenu').length)
      return;
    $('.profile-nav__submenu').hide();
    event.stopPropagation();
  });
  $('.profile-nav__dropdown').click(function () {

    if ($(this).siblings('.profile-nav__submenu').is(':visible')) {
      $(this).siblings('.profile-nav__submenu').hide();
    } else {
      $(this).siblings('.profile-nav__submenu').show();
    }
    return false;
  });

  $('.form-registr').on('click', function (e) {
    e.stopPropagation();
  });

  $("#overlay").on('click', function () {
    $('#overlay').hide();

  });

  // document.querySelector(".comment-rating__plus").addEventListener("click", () => {
  //     var digit = document.querySelector("#span2").innerHTML;
  //     if (pusk.style.color == 'red') {
  //         pusk.style.color = '';
  //         document.querySelector("#span2").innerHTML = digit - 1;
  //     } else {
  //         pusk.style.color = 'red';
  //         document.querySelector("#span2").innerHTML = parseInt(digit) + 1;;
  //     }
  // })


  $('.left-nav-toggle').on('click', function () {
    $('.mobile-navigation').toggle('fast', function () {

    });
  });

  $('.profile-nav').on('click', function () {
    if ($(window).width() < 768) {
      $('.user-navigation').toggle('fast', function () {

      });
    } else {
      return;
    }
  });
});

$(document).ready(function () {
  $('select').niceSelect();

  document.onload = function () {
    $.fancybox.open({
      src: '#modal-create-team',
      type: 'inline',
      touch: false,
      autoFocus: false,
    });
  }

  const likesCounterButton = $('.likes-counter__button');
  const likesValue = $('.likes-counter__value');

  const unlikeClass = 'likes-counter__button--unlike'

  function getLikesNum() {
    return +likesValue.text();
  }

  function toggleLikesCounter(liked) {
    if (liked) {
      likesCounterButton.removeClass(unlikeClass);
    } else {
      likesCounterButton.addClass(unlikeClass);
    }
  }

  function updateCounter(liked) {
    if (liked) {
      likesValue.text(getLikesNum() + 1);
    } else {
      likesValue.text(getLikesNum() - 1);
    }
  }

  likesCounterButton.on('click', function () {
    const toggleUrl = $(this).data('toggle-url');
    const csrfToken = $(this).find('input').val();
    $.ajax({
      url: toggleUrl,
      method: 'POST',
      data: {
        'csrfmiddlewaretoken': csrfToken,
      },
      success(response) {
        toggleLikesCounter(response['liked']);
        updateCounter(response['liked']);
      }
    });
  });

  const initSurvey = (survey) => {
    survey.find('.survey-choice').each(function () {
      const progressBar = $(this).find('.survey-choice__progress');
      progressBar.progressbar({value: progressBar.data('percent')});
    });

    survey.find('.survey-choice-button').on('click', function () {
      const url = $(this).data('url');
      $.ajax({
        method: 'POST',
        headers: {
          'X-CSRFToken': getCsrfToken(),
        },
        url,
        success: (data) => {
          const newSurvey = $(data);
          $(this).parents('.news-survey').replaceWith(newSurvey);
          initSurvey(newSurvey);
        }
      })
    })
  }

  initSurvey($('.news-survey'));

  $('.js-comment-reply').on('click', function (e) {
    e.preventDefault();
    const parentId = $(this).data('parent-id');
    const authorName = $(this).data('author-name');
    const formComment = $('#formComment');
    $([document.documentElement, document.body]).animate({
      scrollTop: formComment.offset().top - 100
    }, 400);
    formComment.find('[name="parent"]').val(parentId);
    formComment.find('[name="text"]').val(`${authorName}, `).focus();
  });

  $('.js-comment-reaction').each(function () {
    const url = $(this).data('reaction-url');
    const rating = $(this).find('.js-comment-reaction__rating');

    function setRating(r) {
      if (r > 0) {
        rating.removeClass('rating--negative');
      } else {
        rating.addClass('rating--negative');
      }
      rating.text(r);
    }

    $(this).find('.js-comment-reaction__add').on('click', function (e) {
      e.preventDefault();
      $.ajax({
        method: 'post',
        url,
        data: {
          csrfmiddlewaretoken: getCsrfToken(),
          reaction: $(this).data('value'),
        },
        success(data) {
          if (data.status === 'ok') {
            setRating(parseInt(data.payload.likes_num));
          }
        },
      });
    })
  });

  $('.js-refresh-ref').on('click', function () {
    const url = $(this).data('url');
    $.ajax({
      method: 'POST',
      url,
      data: {
        csrfmiddlewaretoken: getCsrfToken()
      },
      success(data) {
        if (data.status === 'ok') {
          $('.js-ref-code').val(data.payload.ref_code);
        }
      }
    })
  });

  $('.news-description__content-text').each(function () {
    $(this).find(':has(iframe)').each(function () {
      $(this).addClass("video-container");
    })
  });
});

function getCookie(name) {
  let matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

function getCsrfToken() {
  return getCookie('csrftoken');
}
