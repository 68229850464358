$(document).ready(function () {

  const tabBtn = $('.content-tabs__tab-item');
  const tabBlock = $('.content-section');

  tabBtn.on('click', function () {
    const tabBlockId = $(this).attr('data-tab-id');
    const currentBlock = $('#' + tabBlockId);

    tabBtn.removeClass('tab-item--active');
    $(this).addClass('tab-item--active');

    tabBlock.removeClass('section--active');
    currentBlock.addClass('section--active');
  });

  // tournament tabs
  const tournamentTabBtn = $('.tournament-tabs__item');
  const tournamentTabBlock = $('.tournament-tab-content__block');

  tournamentTabBtn.on('click', function () {
    const tabBlockId = $(this).attr('data-tab-id');
    const currentBlock = $('#' + tabBlockId);

    tournamentTabBtn.removeClass('tab-item--active');
    $(this).addClass('tab-item--active');

    tournamentTabBlock.removeClass('section--active');
    currentBlock.addClass('section--active');
  });
});
