$(document).ready(function () {

  const tabsBtn = $('.tabs__panel-item');

  tabsBtn.on('click', function () {
    const tabBlock = $(this).closest('.tabs');
    const tabs = tabBlock.find('.tabs__item');
    const tabsBtn = tabBlock.find('.tabs__panel-item');
    const tabId = $(this).attr('data-tab');

    tabsBtn.removeClass('tabs__panel-item--active');
    $(this).addClass('tabs__panel-item--active');

    tabs.removeClass('tabs__item--active');
    $('#' + tabId).addClass('tabs__item--active');
  })

});
