$(window).on('load', function () {
  const closeAuthModal = $('.info__header-close');
  const recoveryPassLink = $('.info__forget-password');

  const recoveryForm = $('#recovery');
  const entryForm = $('#entry');

  // $('#enter').on('click', function (e) {
  //   e.preventDefault();
  //   $("#overlay").show();
  // });
  //
  // $('.info__header-close').on('click', function () {
  //   $("#entry").trigger('reset');
  //   $("#registr").trigger('reset');
  //   $("#overlay,#name-error, #pass-error, #login-error, #pass-reg-error,#pass-repeat-reg-error,#email-reg-error").hide();
  // });

  closeAuthModal.on('click', function () {
    $.fancybox.close({
      src: '#modal-auth',
    });
    $('.form').trigger('reset');
  });

  recoveryPassLink.on('click', function () {
    recoveryForm.removeClass('form--hidden');
    entryForm.addClass('form--hidden');
    $(this).hide();
  });



  $('[data-fancybox]').fancybox({
    toolbar: false,
    smallBtn: true,
    touch: false,
    autoFocus:false,
    afterLoad: function () {
      let fancyboxSlide = document.querySelectorAll('.fancybox-slide');
      fancyboxSlide.forEach(function (element) {
        bodyScrollLock.disableBodyScroll(element);
      });
    },
    beforeClose: function () {
      if($('.fancybox-slide').length === 1) {
        bodyScrollLock.clearAllBodyScrollLocks();
      }
    },
  });

  const recoveryEmail = $('#recovery [type="email"]');
  const regEmail = $('#registr [type="email"]');

  recoveryEmail.add(regEmail).on('input',function () {
   let value = $(this).val();
   $('.modal--reg-email, .modal--recovery-email').find('.email-recovery').text(value);
  });


  // $('#allow').on('change', function () {
  //     let check = $(this).is(':checked');
  //     let btn = $(this).closest('.entry').find('.button__come');

  //     if (check) {
  //         btn.removeAttr('disabled');
  //     } else {
  //         btn.attr('disabled', true)
  //     }
  // });

  //$('#allow-reg').on('change', function () {
  //  let check = $(this).is(':checked');
  //  let btn = $(this).closest('.registr').find('.button__come');
//
  //  if (check) {
  //    btn.removeAttr('disabled');
  //  } else {
  //    btn.attr('disabled', true)
  //  }
  //});

  $('#tab-enter').on('click', function () {
    $("#registr").trigger('reset');
  });

  $('#tab-reg').on('click', function () {
    $("#entry").trigger('reset');
  });

  function hideTabs(sel) {
    const target = $(sel);
    target.removeClass('show');
    target.addClass('hide');
  }

  function showTabContent(sel) {
    const target = $(sel);

    target.removeClass('hide');
    target.addClass('show');
  }

  $('.info__header-tab').on('click', event => {
    const target = $(event.target);

    if (!target.hasClass('non-border')) {
      $('.info__header-tab').removeClass('non-border');
      target.addClass('non-border');

      hideTabs('.info__tabcontent');
      showTabContent(target.data('tab-target'));
    }

    recoveryForm.addClass('form--hidden');
    entryForm.removeClass('form--hidden');
    recoveryPassLink.show();
  });
});
