$(document).ready(function () {

  const tournamentGrid = $('.tournament-grid__slider');
  const tournamentCols = $('.tournament-slide');
  const firstList = $(tournamentCols[0]).find('.tournament-slide__teams-list');

  const setHeightSlides = () => {
    tournamentGrid.find('.tournament-slide__teams-list').each(function () {
      $(this).css('height', firstList.innerHeight());
    })
  };

  setHeightSlides();

  const genLines = () => {
    const pairs = tournamentGrid.find('.tournament-slide__teams-pair:not(.pair--empty)');
    const pairLine = `<span class="tournament-slide__pair-line"></span>`;
    pairs.append(pairLine);
  };

  genLines();

  const setLinesPosition = () => {
    tournamentCols.each(function (index) {
      const col = $(this);
      if ((index + 1) < tournamentCols.length) {
        const pairs = col.find('.tournament-slide__teams-pair');
        const nextPairs = $(tournamentCols[index + 1]).find('.tournament-slide__teams-pair');
        let pairCounter = 0;

        pairs.each(function (index) {
          if(index % 2 === 0) {

            const pair1 = $(this);
            const pair2 = $(pairs[index + 1]);
            const result = $(nextPairs[pairCounter]);

            if (pair1.hasClass('pair--empty') && pair2.hasClass('pair--empty')) {
              result.addClass('pair--single');
            }

            const lineHeight = result.offset().top - pair1.offset().top;

            pair1.find('.tournament-slide__pair-line').css({'height': lineHeight});
            pair2.find('.tournament-slide__pair-line').css({'height': lineHeight});

            pairCounter++;
          }
        });
      }
    })
  };
  setTimeout(setLinesPosition, 1000);

});
