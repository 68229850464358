(function( $ ){

  $.fn.easyForm = function( options= {}) {

    const {
      wrapperClass = 'field-wrapper',
      tooltipClass = 'tooltips',
      tooltips = true,
      resetForm = true,
      beforeResetForm = function(){},
      messages: {
        messageEmpty = 'Поле обязательно для заполнения',
        messageMin = 'Минимальное кол-во символов: {n}'
      } = {},
      noneFieldError = false,
      validators = false,
      privacyCheckbox = false,
      submitButton = '[type="submit"]',
      googleCaptcha3 = false,
      validate = function(){},
      beforeSend = function(){},
      success = function(){},
      rejected = function(){},
      error = function(){},
    } = options ;

    return this.each(function() {
      var ths = $(this);

      const inputFields = ths.find('input:not([type="hidden"], [type="checkbox"], [type="radio"]), textarea, select')
        .on('input', function () {
          const field = $(this);
              field.removeClass('is-invalid is-valid');
          if (isEmpty(field) && isRequired(field)) {
            setErrorField(field);
          } else {
            field.addClass('is-valid');
            hideTooltip(field);
          }
        });

      if(noneFieldError) {
        const allErrorField = $(noneFieldError);
        if (allErrorField.length) {
          allErrorField.hide();
        }
      }

      inputFields.each(wrapping);
      if (tooltips) {
        createTooltips(inputFields);
      }
      if (privacyCheckbox) {
        const button = ths.find(submitButton);
        const checkbox = ths.find(privacyCheckbox);

        checkbox.on('change', function () {
          const check = $(this).prop('checked');
          button.prop('disabled', !check);
        });
        controlPrivacy(ths);
      }

      ths.on('reset', function () {
        clearForm($(this));
      });

      ths.on('submit', function (e) {
        e.preventDefault();
        const form = $(this);
        const button = form.find(submitButton);
        const url = form.attr('action');
        const method = form.attr('method') || 'POST';
        let data = new FormData(form[0]);

        if(noneFieldError) {
          const allErrorField = form.find(noneFieldError);
          if (allErrorField.length) {
            allErrorField.hide();
          }
        }

        if (validation(form)) {

          if(googleCaptcha3) {
            if (RECAPTCHA_KEY !=='None') {
              grecaptcha.execute(RECAPTCHA_KEY, {action: 'generic'}).then(token => {
                data['g-recaptcha-response'] = token;
                beforeSend();
                button.prop('disabled', true);
                sendAjax();
              });
            } else {
              beforeSend();
              button.prop('disabled', true);
              sendAjax();
            }
          } else {

            beforeSend();
            button.prop('disabled', true);
            sendAjax();

          }

          function sendAjax() {
            $.ajax(url, {
              method: method,
              data: data,
              processData: false,
              contentType: false,
              success: function (result) {
                if (result.status === "success") {
                  if (resetForm) {
                    clearForm(form);
                  }
                  success();
                } else {
                  for (const errorName in result.errors) {
                    let field = form.find('[name="'+ errorName +'"]');
                    if (noneFieldError && errorName === '__all__') {
                      const allErrorField = form.find(noneFieldError);
                      allErrorField.text(result.errors[errorName][0]);
                      allErrorField.show();

                    } else {
                      setErrorField(field, result.errors[errorName][0]);
                    }
                  }
                  error();
                }
                controlPrivacy(form)
              },
              error: function () {
                rejected();
                controlPrivacy(form)
              }
            });
          }
        }
      });

    });

    function validation(form) {
      let errors = false;
      const values = {};

      form.find('input:not([type="hidden"], [type="checkbox"], [type="radio"]), textarea').each(function () {
        const field = $(this);
        const value = field.val();
        const fieldName = field.attr('name');
        values[fieldName] = value;

        if (isRequired(field) && isEmpty(field)) {
          setErrorField(field);
          errors = true;
          return;
        }
        if (!isEmpty(field) && isMin(field)) {

          const reg = /\{\w+\}/;
          newMessageMin = messageMin.replace(reg, field.attr('minlength'));

          setErrorField(field, newMessageMin);

          errors = true;
          return;
        }

        if (validators) {

          if (validators[fieldName]) {
            for (const method in validators[fieldName]) {
              const func = validators[fieldName][method];
              if (func(value)) {
                setErrorField(field, func(value));
                errors = true;
                return;
              }
            }
          }
        }

      });

      const validateResult = validate(values);

      if (validateResult) {
        for (fieldName in validateResult) {
          const field = form.find('[name="'+ fieldName +'"]');
          setErrorField(field, validateResult[fieldName]);
        }
        errors = true;
      };

      return !errors;
    }

    function isEmpty(field) {
      let value = field.val();
      value = (value || '').trim();
      return (value === '');
    }
    function isMin(field) {
      const value = field.val();
      const minLength = field.attr('minlength');
      if (minLength) {
        return value.length < +minLength;
      }
    }

    function isRequired(field) {
      return (field.attr('required'))
    }

    function wrapping(){
      const field = $(this);
      field.wrap('<div class="'+ wrapperClass +'"></div>');
    }

    function createTooltips(inputFields) {
      inputFields.each(function () {
        const field = $(this);
        const tooltip = '<span class="'+ tooltipClass +'"></span>';
        field.parent().append(tooltip);
        genTooltipMessage(field);
        hideTooltip(field);
      })
    }

    function hideTooltip(field) {
      field.parent().find('.' + tooltipClass).fadeOut(0);
    }
    function showTooltip(field) {
      field.parent().find('.' + tooltipClass).fadeIn(200);
    }

    function genTooltipMessage(field, message) {
      const tooltip = field.parent().find('.' + tooltipClass);
      if (message) {
        tooltip.html(message)
      } else if (isRequired(field)) {
        tooltip.html(messageEmpty);
      } else {
        tooltip.html('');
      }
    }

    function setErrorField(field, message) {
      field.addClass('is-invalid');
      genTooltipMessage(field, message);
      showTooltip(field);
    }



    function controlPrivacy(form) {
      const button = form.find(submitButton);
      const checkbox = form.find(privacyCheckbox);
      if (checkbox.length && checkbox.prop('checked') !== true) {
        button.prop('disabled', true);
      } else {
        button.prop('disabled', false);
      }
    }

    function clearForm(form) {
      beforeResetForm();

      const fields = form.find('input:not([type="hidden"], [type="checkbox"], [type="radio"]), textarea');
      fields.each(function () {
        hideTooltip($(this));
      });
      fields.removeClass('is-valid is-invalid');
      form[0].reset();
      controlPrivacy(form);

      if (noneFieldError) {
        form.find(noneFieldError).text('').hide();
      }

    }

  };
})( jQuery );
