$('document').ready(function () {

  const matchPair = $('.tournament-slide__teams-pair');

  matchPair.on('click', function () {
    const url = '//' + location.host + $(this).attr('data-url');

    $('.modal-content-wrapper').load(url + ' .round-detail', initModal);

    $.fancybox.open({
      src  : '#modal-match-detail',
      type : 'inline',
      touch: false,
      autoFocus:false,
    });

  });

  function initModal() {

    const matchDetailBlock = $('.match-detail');
    const matchChatBlock = $('.match-chat');
    const chatLink = $('.modal__chat-link');
    const detailLink = $('.modal__detail-link');

    chatLink.on('click', function () {
      showChat();
    });

    detailLink.on('click', function () {
      showMatchDetail();
    });

    function showChat() {
      matchDetailBlock.hide();
      matchChatBlock.show();
    }
    function showMatchDetail() {
      matchDetailBlock.show();
      matchChatBlock.hide();
    }

    //send chat-form
    const chatForm = $('.chat-room__form');

    chatForm.on('submit', function (e) {
      e.preventDefault();
      const form = $(this);
      const method = form.attr('method') || 'POST';
      const url = form.attr('action');
      let data = new FormData(form[0]);

      const message = form.find('textarea').val().trim();

      if (attachedFiles.length) {
        for (let i=0; i < attachedFiles.length; i++) {
          data.append(attachedFiles[i].name, attachedFiles[i]);
        }
      }

      if (message || attachedFiles.length) {
         $.ajax(url, {
         method: method,
         data: data,
         processData: false,
         contentType: false,
         success: function (result) {

         }
       })

        clearForm();
      }

    });

    //attach files
    let attachedFiles = [];
    const filesUpload = document.querySelector('.chat-room__form-files-input');
    $(filesUpload).on('change', function () {
      const files = filesUpload.files;
      const mimes = [
        {
          mime: 'image/jpeg',
          pattern: [0xFF, 0xD8, 0xFF],
          mask: [0xFF, 0xFF, 0xFF],
        },
        {
          mime: 'image/png',
          pattern: [0x89, 0x50, 0x4E, 0x47],
          mask: [0xFF, 0xFF, 0xFF, 0xFF],
        },
      ];

      for (let i = 0; i < files.length; i++) {
        loadMime(files[i], mimes, function () {
          attachedFiles.push(files[i]);
          setFileList();
        });
      }
    });

    // check type upload file
    function loadMime(file, mimes, callback) {

      function check(bytes, mime) {
        for (var i = 0, l = mime.mask.length; i < l; ++i) {
          if ((bytes[i] & mime.mask[i]) - mime.pattern[i] !== 0) {
            return false;
          }
        }
        return true;
      }

      var blob = file.slice(0, 4);
      var reader = new FileReader();
      reader.onloadend = function (e) {
        if (e.target.readyState === FileReader.DONE) {
          var bytes = new Uint8Array(e.target.result);
          for (var i = 0, l = mimes.length; i < l; ++i) {
            if (check(bytes, mimes[i])) {
              callback();
            }
          }
        }
      };
      reader.readAsArrayBuffer(blob);
    }

    //

    const fileList = $('.chat-room__form-files-list');
    function setFileList() {
      fileList.html('');

      for (let i = 0; i < attachedFiles.length; i++) {
        const fileItem = `
                        <li class="chat-room__form-files-item" id="file-${i}">
                        ${attachedFiles[i].name}
                        <span class="chat-room__form-files-del"></span>
                        </li>
                        `;
        fileList.append(fileItem);
      }
    }

    // del files
    fileList.on('click', '.chat-room__form-files-del', function () {
      const id = $(this).parent().attr('id').split('-')[1];
      attachedFiles.splice(id, 1);
      setFileList();
    })


    function clearForm() {
      attachedFiles = [];
      chatForm.find('textarea').val('');
      fileList.html('');
    }

    $('.chat-room__message-img-link').on('click', function () {
      const src = $(this).attr('data-src');
      $.fancybox.open({
        src: src,
        autoFocus: false,
        touch: false,
      })
    });

  }

});
