$(document).ready(function () {
  $('.js-ajax-pager').each(function () {
      const container = $(this);
      const paginatedList = $($(this).data('paginated-list'));

      const handler = function (e) {
        e.preventDefault();

        const url = $(this).attr('href');
        $.ajax({
          url,
          method: 'GET',
          success: function (result) {
            paginatedList.append(result['list']);
            if (result['button_more'] !== null) {
              container.html(result['button_more']);
              bindHandler();
            } else {
              container.html('');
            }
          }
        });
      }

      const bindHandler = function () {
        container.find('.js-ajax-pager__link').on('click', handler);
      }
      bindHandler();
    }
  )
})
