$(document).ready(function() {
  $('#entry').easyForm({
    wrapperClass: 'input-group',
    tooltipClass: 'input-group__error',
    noneFieldError: '.form__all-errors',
    privacyCheckbox: '.checkbox-group__input',
    submitButton: '.form__submit-btn',
    success() {
      location.reload();
    }
  });

  $('#recovery').easyForm({
    wrapperClass: 'input-group',
    tooltipClass: 'input-group__error',
    noneFieldError: '.form__all-errors',
    success() {
      $.fancybox.open({
        src: '#modal-recovery-email',
        type : 'inline',
      });
    }
  });

  $('#registr').easyForm({
    wrapperClass: 'input-group',
    tooltipClass: 'input-group__error',
    noneFieldError: '.form__all-errors',
    privacyCheckbox: '.checkbox-group__input',
    submitButton: '.form__submit-btn',
    validate(values) {
      const errors = {};

      if (values.password !== values.password2) {
        errors.password2 = 'Введенные пароли должны совпадать';
      }

      if (!_.isEmpty(errors)) {
        return errors;
      }
    },
    success() {
      $.fancybox.open({
        src: '#modal-reg-email',
        type : 'inline',
      });
    }
  })

  const photoUpload = document.querySelector('.form__input-file');
  const currentImg = $('.aside-profile-avatar-image');
  let currentImgSrc = currentImg.attr('src');
  const settingsImg = $('.form__profile-photo-img');
  let userPhoto = '';

  $('.form__button.button--cancel').on('click', function (e) {
    e.preventDefault();
    $.fancybox.close();
    settingsImg.attr('src', currentImgSrc);
    photoUpload.value = '';
  });

  $('.form.form--subscribe').on('submit', function (e) {
    e.preventDefault();
    const optionId = $(this).find('.subscribe-radio:checked').val();
    const csrfToken = $(this).find('[name="csrfmiddlewaretoken"]').val();
    $.ajax({
      method: 'post',
      url: $(this).attr('action'),
      data: {
        option: optionId,
        csrfmiddlewaretoken: csrfToken,
      },
      success(e) {
        if (e.status === 'success') {
          location.href = e.payload;
        }
      },
      error(e) {
        console.log(e);
      }
    });
  });

  $('.form.form--reload').easyForm({
    wrapperClass: 'input-group',
    tooltipClass: 'input-group__error',
    noneFieldError: '.form__all-errors',
    success() {
       location.reload();
    }
  });

  $('.form.form--reset-password-done').easyForm({
    wrapperClass: 'input-group',
    tooltipClass: 'input-group__error',
    noneFieldError: '.form__all-errors',
    success() {
      $.fancybox.open({
        src: '#modal-reset-password-success',
        type : 'inline',
      });
       setTimeout(() => {
         location.href = "/";
       }, 2000);
    }
  });

  //========= upload file =================

  $(photoUpload).on('change', function () {
    //List of known mimes
    var mimes = [
      {
        mime: 'image/jpeg',
        pattern: [0xFF, 0xD8, 0xFF],
        mask: [0xFF, 0xFF, 0xFF],
      },
      {
        mime: 'image/png',
        pattern: [0x89, 0x50, 0x4E, 0x47],
        mask: [0xFF, 0xFF, 0xFF, 0xFF],
      },
    ];

    loadMime(photoUpload.files[0], mimes, function (result) {
      if (result) {
        setPhoto(photoUpload.files[0], settingsImg);
      } else {
        console.log('Выберите другой тип файла');
      }
    });
  });

  // check type upload file
  function loadMime(file, mimes, callback) {

    function check(bytes, mime) {
      for (var i = 0, l = mime.mask.length; i < l; ++i) {
        if ((bytes[i] & mime.mask[i]) - mime.pattern[i] !== 0) {
          return false;
        }
      }
      return true;
    }

    var blob = file.slice(0, 4); //read the first 4 bytes of the file
    var reader = new FileReader();
    reader.onloadend = function (e) {
      if (e.target.readyState === FileReader.DONE) {
        var bytes = new Uint8Array(e.target.result);
        for (var i = 0, l = mimes.length; i < l; ++i) {

          if (check(bytes, mimes[i])) {
            return callback(true); //callback("Mime: " + mimes[i].mime + " <br> Browser:" + file.type);
          }
        }
        return callback(false); //callback("Mime: unknown <br> Browser:" + file.type);
      }
    };
    reader.readAsArrayBuffer(blob);
  }

  function setPhoto(file, img) {
    var reader = new FileReader();
    reader.onload = (function (theFile) {
      return function (e) {
        img.attr('src', e.target.result);
      };
    })(file);
    reader.readAsDataURL(file);
  }

  $('.command-list__leave').on('click', function (e) {
    $('.leave-team-id').val($(this).data('team-id'));
  });
});
