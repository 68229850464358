$(window).on('load', function () {
  const streamSlider = $('.stream-slider');
  const tournamentSlider = $('.tournament-grid__slider');

  $('.slider').slick({
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
  });

  streamSlider.slick({
    dots: false,
    infinite: true,
    arrows: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [{
      breakpoint: 920,
      settings: {
        slidesToShow: 3,
      }
    },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
        }
      }
    ]
  });

  tournamentSlider.slick({
    dots: false,
    infinite: false,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1
  })


});
